/* colors */
html,
body {
  font-family: "PingFangSC-Regular", "Microsoft Yahei", "Helvetica Neue", Helvetica, "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Geneva, Verdana, Arial, sans-serif;
  background: #FFFFFB;
  text-align: center;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lucida Bright", Georgia, TimesNewRoman, "Times New Roman", Times, Baskerville, Georgia, serif;
}
pre,
code {
  font-family: "Lucida Console", "Lucida Sans Typewriter", monaco, "Bitstream Vera Sans Mono", Consolas, "Lucida Console", monospace;
}
nav ul {
  list-style: none;
}
nav ul li {
  display: inline-block;
}
nav ul li a {
  color: #E16B8C;
  text-decoration: underline;
}
.welcome {
  font-size: 20px;
}
.welcome a {
  text-decoration: none;
  color: #0C0C0C;
}
header {
  border-bottom: 1px solid #2EA9DF;
}
footer {
  padding: 10px 0;
  color: #E16B8C;
  font-size: 12px;
}
.post {
  padding: 30px 10px;
  color: #0B1013;
  border-bottom: 1px solid #5DAC81;
}
.post .label {
  font-size: 12px;
  color: #787D7B;
}
.post .label .link {
  color: #2EA9DF;
}
.post .label .link:visited {
  color: #787D7B;
}
.post .body {
  font-size: 18px;
  line-height: 2em;
  white-space: pre-wrap;
}
.post .resources {
  margin: 10px 0;
  padding: 0;
  list-style: none;
}
.post .resources img {
  max-width: 400px;
  height: auto;
  cursor: zoom-in;
}
.post .resources audio {
  width: 100%;
}
.post .resources li {
  text-align: center;
}
.post .resources li a {
  font-size: 12px;
  color: #2D6D4B;
}
.home .posts {
  margin: 20px 0;
  width: 700px;
  margin: auto;
}
.home .posts .post {
  text-align: left;
}
.home .posts .post .label {
  float: right;
}
.add_post .form {
  margin: 20px auto;
  width: 400px;
}
.add_post .form textarea {
  background-color: #f9f9f9;
  border-radius: 4px;
  border: 1px solid #ddd;
  outline: none;
  background-clip: padding-box;
  padding: 0;
  margin: 0;
  font-size: 16px;
  height: 200px;
  width: 400px;
  box-sizing: border-box;
}
.add_post .form .resources {
  text-align: left;
}
.add_post .form .resources .resource {
  display: none;
}
.add_post .form .resources .resource:first-child {
  display: block;
}
.add_post .form .error-message {
  color: red;
  font-size: 12px;
  text-align: left;
}
.add_post .form .btns {
  margin: 10px 0;
}
.add_post .form .btns .submit {
  width: 100px;
  height: 40px;
  outline: none;
  background-color: #2EA9DF;
  font-family: 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  border: none;
  border-radius: 3px;
  color: #FFF;
}
@media only screen and (max-device-width: 736px) {
  .add_post {
    text-align: center;
  }
  .add_post .form textarea {
    width: 100%;
  }
  .home .posts {
    width: 100%;
  }
}
.login .page-title {
  font-weight: 300;
}
.login .message {
  color: red;
  font-size: 14px;
}
.login form > div {
  margin: 10px 0;
}
.login form > div label {
  display: block;
}
.login form > div input {
  margin: 5px 0;
  padding: 0 3px;
  width: 200px;
  line-height: 24px;
}
.login form button {
  width: 80px;
  height: 24px;
  outline: none;
  border: none;
}
.single-post .post {
  border-bottom: none;
}
.single-post .post .body {
  margin: 4em 0;
  font-size: 2em;
  font-weight: normal;
}
.single-post .post .label .time {
  display: block;
}
.single-post .post .label .link {
  color: #0C0C0C;
  font-size: 12px;
}
.single-post .post .resources img {
  max-width: 100%;
}
